import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  text-align: left;
  color: white;
`;

const StyledH1 = styled.h1`
  padding: 1rem;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const PrivacySection = styled.div`
  padding: 1rem 3rem;
`;

const PrivacyPage = () => {
  return (
    <Layout title="Privacy">
      <SEO pagetitle="Privacy" pagedesc="プライバシーポリシー" />
      <ContentWrapper>
        <Main>
          <PrivacySection>
            <StyledH1>個人情報の利用目的</StyledH1>
            <p>
              当サイトでは、お問い合わせの際にメールアドレスを入力していただく必要がございます。
              取得したメールアドレスは、お問い合わせに対する回答を電子メール等でご連絡する場合にのみ利用するものであり、これ以外の目的では利用いたしません。また、第三者に開示することもございません。
            </p>
          </PrivacySection>
          <PrivacySection>
            <StyledH1>広告について</StyledH1>
            <p>
              当サイトでは、第三者配信の広告サービス「Google
              AdSense」を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
              クッキー（Cookie）を使用することで当サイトはお客様のコンピューターを識別できるようになりますが、個人を特定できるものではございません。
              クッキー（Cookie）を無効にする方法やGoogle AdSenseに関する詳細は
              <a href="https://policies.google.com/technologies/ads?hl=ja">
                「広告 – ポリシーと規約 – Google」
              </a>
              をご確認ください。
              また、Amazonのアソシエイトとして、ShogiStatsは適格販売により収入を得ています。
            </p>
          </PrivacySection>

          <PrivacySection>
            <StyledH1>アクセス解析ツールについて</StyledH1>
            <p>
              当サイトでは、アクセス解析ツール「Googleアナリティクス」を利用しており、トラフィックデータ収集のため、クッキー（Cookie）を使用しております。
              トラフィックデータは匿名で収集されており、個人を特定できるものではございません。
            </p>
          </PrivacySection>

          <PrivacySection>
            <StyledH1>免責事項について</StyledH1>
            <p>
              当サイトからのリンクやバナー等で移動したサイトにおいて提供される情報、サービス等について一切の責任を負いません。
              また、当サイトのコンテンツについて、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではございません。情報が古くなっている場合もございます。
              当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
            </p>
          </PrivacySection>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export default PrivacyPage;
